import Cookies from "js-cookie";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import pick from "lodash/pick";
import * as Sentry from "@sentry/react";

import dq_fetch, { dq_graphql } from "redux/utils/dq_fetch";

import EventsTracker from "helpers/events_tracker";
import {
  fetchPaymentFeatures,
  fetchPlanPricing,
} from "redux/modules/plan_info/plan_info_thunks";
import { fetchReferralDiscount } from "redux/modules/payment_info/payment_info_thunks";
import { getProfile } from "redux/modules/profile_info/profile_info_thunks";
import { fetchPaywallSettings } from "redux/modules/paywall_info/paywall_info_thunks";
import { fetchAbLessonData } from "redux/modules/catalog_info/catalog_info_thunks";
import { window_location } from "helpers/window_location";
import { selectors as utm_selectors } from "../utm_codes";
import { actions, private_actions, selectors } from "./user_info_simple";

const REFRESH_TIMEOUT = 1500;
const get_token = () => Cookies.getJSON("token");
const getImpersonateToken = () => Cookies.getJSON("dq-impersonate-token");
const set_token = full_call => {
  const token = pick(full_call, ["token", "cm_jwt"]);
  Cookies.set("token", token);
  if (__PROD__)
    Cookies.set("dq-shared-auth", token, { domain: ".dataquest.io" });
};

const getGAClientID = () => {
  const cookie = {};
  document.cookie.split(";").forEach(function(el) {
    const splitCookie = el.split("=");
    const key = splitCookie[0].trim();
    const value = splitCookie[1];
    cookie[key] = value;
  });
  return cookie?._ga?.substring(6);
};

const get_csrf_token = () =>
  dq_fetch("/api/v2/accounts/get_csrf_token/", { type: "POST" });

const setUniqueSessionId = () => (_dispatch, _getState) => {
  if (window?.localStorage?.getItem("dq-session-uid")) return;
  let sessionUid = window.crypto.getRandomValues(new Uint32Array(1))[0];
  sessionUid = `${sessionUid.toString(16)}-${Date.now()}`;
  window?.localStorage?.setItem("dq-session-uid", sessionUid);
};

const getSkipOnboardingData = () => {
  const params = new URLSearchParams(window.location.search);
  const pathSlugQuery = params.get("path") || "";
  const pathVersionQuery = params.get("version") || "";
  const courseSlugQuery = params.get("course") || "";
  const lessonSequenceQuery =
    params.get("mission") || params.get("lesson") || "";
  if (pathSlugQuery || courseSlugQuery || lessonSequenceQuery)
    return {
      version: pathVersionQuery,
      path: pathSlugQuery ? `/path/${pathSlugQuery}` : "",
      course: courseSlugQuery ? `/course/${courseSlugQuery}` : "",
      lesson: lessonSequenceQuery ? `/m/${lessonSequenceQuery}` : "",
    };
  return null;
};

const new_token = (dispatch, getState, is_new_user) => data => {
  if (isEmpty(data)) {
    dispatch(private_actions.setAskingForUser(false));
    EventsTracker.trackPending();
    return {};
  }

  // Handle 2FA
  if (data.unauthorized && data.mfa_required) {
    return dispatch(private_actions.setDisplayMFA(true));
  }

  // handle 401 errors
  if (data.unauthorized) {
    EventsTracker.trackPending();
    return Promise.reject(new Error("unauthorized"));
  }

  if (data.new_user === false) {
    is_new_user = false;
  }

  set_token(data);

  if (!__DEV__) {
    const traits = pick(data, [
      "date_joined",
      "date_joined_timestamp",
      "email",
      "first_name",
      "id",
      "is_staff",
      "last_login",
      "last_name",
      "mfa_enabled",
      "onboarding_status",
      "permissions",
      "profile",
      "username",
    ]);
    Sentry.setUser({ ...traits });
  }
  dispatch(private_actions.set_info({ info: data, is_new_user }));
  dispatch(private_actions.setDisplayMFA(false));
  dispatch(fetchPaywallSettings());
  dispatch(fetchPaymentFeatures());
  dispatch(fetchSubscribeCTASettings());
  dispatch(fetchPlanPricing());
  dispatch(fetchFeatureStatus());
  if (data.profile?.is_referee) dispatch(fetchReferralDiscount());
  dispatch(
    getProfile({
      user_id: data.id,
    }),
  );

  setup_analytics(dispatch, getState);
  EventsTracker.trackPending();
  if (is_new_user)
    setTimeout(() => refresh_user(true)(dispatch, getState), REFRESH_TIMEOUT);
  return data;
};

const setReferral = (code, email) => (dispatch, getState) => {
  const user_id = selectors.user_id(getState());
  dispatch(private_actions.change_errors({}));
  return dq_fetch(`/api/v1/accounts/create_referral/`, {
    post_obj: { user_id, referral_code: code, referral_email: email },
    type: "POST",
  })
    .then(() => refresh_user()(dispatch, getState))
    .catch(err => {
      console.log(err);
    });
};

const refresh_user = (silent = false) => (dispatch, getState) => {
  const is_new_user = selectors.is_new_user(getState());
  if (!silent) dispatch(private_actions.setAskingForUser(true));
  let authToken = "";
  // eslint-disable-next-line no-underscore-dangle
  if (Cookies.get("forcedAuthSubscriptionStatus")) {
    authToken = `?force_auth=${Cookies.get("forcedAuthSubscriptionStatus")}`;
  }

  return dq_fetch(`/api/v1/accounts/get_auth_token_data/${authToken}`)
    .then(data => {
      if (isEmpty(data)) return;
      set_token(data);
      if (!__DEV__) {
        Sentry.setUser({ email: data.email, id: data.id });
      }
      dispatch(private_actions.set_info({ info: data, is_new_user }));
      dispatch(private_actions.setAskingForUser(false));
      dispatch(
        getProfile({
          user_id: data.id,
        }),
      );
    })
    .catch(e => {
      removeCookie();
      window.location.reload();
    });
};
const INTERNAL_ERROR = 500;
const translate_error = error => {
  if (error.response && error.response.status === INTERNAL_ERROR) {
    return Promise.resolve(null);
  }
  if (error.response) return error.response.json().catch(() => null);
  return Promise.resolve(null);
};
const make_log_error = (set_errors, default_message, dispatch) => data => {
  if (data === undefined) return; // from new_token

  if (!isObject(data) || isEmpty(data)) {
    // like html response
    data = default_message;
  }
  dispatch(set_errors(data));
};

const login = credentials => (dispatch, getState) => {
  const post_obj = credentials;
  dispatch(private_actions.login_errors({}));

  return get_csrf_token()
    .then(() => {
      const csrf_token = Cookies.get("csrftoken");
      if (!csrf_token) {
        throw new Error("Missing token");
      }
      return dq_fetch("/api/v2/accounts/get_auth_token/", {
        post_obj,
        csrf: true,
      });
    })
    .then(new_token(dispatch, getState, false))
    .catch(error => {
      // handle 401 errors
      if (error.message && error.message === "unauthorized") {
        return dispatch(
          private_actions.login_errors({
            error: ["Credentials could not be verified"],
          }),
        );
      }

      // handle missing csrf token
      if (error.message && error.message === "Missing token") {
        return dispatch(
          private_actions.login_errors({ error: ["Missing token"] }),
        );
      }

      return translate_error(error).then(
        make_log_error(
          private_actions.login_errors,
          { error: ["Incorrect credentials"] },
          dispatch,
        ),
      );
    });
};

const signup = credentials => (dispatch, getState) => {
  const marketing_info = get_marketing_info_from_cookies();
  const post_obj = { ...credentials, ...marketing_info };

  return get_csrf_token().then(() =>
    dq_fetch("/api/v1/accounts/create_user_from_scratch/", {
      post_obj,
      csrf: true,
    })
      .then(new_token(dispatch, getState, true))
      .catch(error =>
        translate_error(error).then(
          make_log_error(
            private_actions.signup_errors,
            [{ general: "Incorrect credentials" }],
            dispatch,
          ),
        ),
      ),
  );
};

/**
 * Set skipped onboarding status in BE and provide a reason
 */
const setSkipOnboarding = (reason, fromIframe = false, source) => (
  dispatch,
  getState,
) => {
  const skipOnboarding = `
  mutation SkipOnboarding {
    skip_onboarding(reason: "${reason}", ${
    source ? `source: "${source}", ` : ""
  }iframe_source: ${fromIframe}) {
      success
    }
  }
  `;
  return dq_graphql(skipOnboarding).then(() =>
    refresh_user()(dispatch, getState),
  );
};

/**
 * Extract information from cookies starting with `dataquest-cac`
 * Cookies are set from the Marketing site (Wordpress) under the domain '.dataquest.io'
 * Extractable infromation:
 *  1. referer_url : Extract from cookie "dataquest-cac-referer-url" else retrieve from document.referrer
 *  2. landing_page: Extract from cookie "dataquest-cac-landing-page"
 *  3. utm_source: Extract from cookie "dataquest-cac-utm-source"
 *  4. utm_medium: Extract from cookie "dataquest-cac-utm-medium"
 *  5. utm_campaign: Extract from cookie "dataquest-cac-utm-campaign"
 *  6. utm_content: Extract from cookie "dataquest-cac-utm-content"
 *  7. utm_term: Extract from cookie "dataquest-cac-utm-term"
 *  8. landing_page_last_touch: Extract from cookie or referrer or pathname
 */
const get_marketing_info_from_cookies = () => {
  let marketing_info = {};

  const referer_url_from_cookie = Cookies.get("dataquest-cac-referer-url");
  const referer_url = referer_url_from_cookie || document.referrer;
  // Check that referer_url is not internal (from dataquest)
  if (referer_url && referer_url.indexOf("dataquest.io") === -1) {
    marketing_info = { ...marketing_info, referer_url };
  }

  const landing_page_from_cookie = Cookies.get("dataquest-cac-landing-page");
  const landing_page = landing_page_from_cookie || window.location.pathname;

  const landing_page_last_touch_from_cookie = Cookies.get(
    "dataquest-cac-landing-page-last-touch",
  );
  const landing_page_last_touch =
    landing_page_last_touch_from_cookie ||
    document.referrer ||
    window.location.pathname;

  const extracted_info_from_cookies = {
    landing_page,
    landing_page_last_touch,
    utm_source: Cookies.get("dataquest-cac-utm-source"),
    utm_medium: Cookies.get("dataquest-cac-utm-medium"),
    utm_campaign: Cookies.get("dataquest-cac-utm-campaign"),
    utm_content: Cookies.get("dataquest-cac-utm-content"),
    utm_term: Cookies.get("dataquest-cac-utm-term"),
  };

  const valid_values_from_cookies = remove_undefined_values(
    extracted_info_from_cookies,
  );
  marketing_info = { ...marketing_info, ...valid_values_from_cookies };

  marketing_info.signup_form = "app";

  return marketing_info;
};

const remove_undefined_values = cookies => {
  const dict = {};
  const entries = Object.entries(cookies);
  entries.forEach(([cookie_name, cookie_value]) => {
    if (typeof cookie_value !== "undefined") {
      dict[cookie_name] = cookie_value;
    }
  });
  return dict;
};
const forgot_password = credentials => (dispatch, _getState) => {
  const post_obj = credentials;
  return dq_fetch("/api/v1/accounts/reset_password/", { post_obj })
    .then(() => {
      dispatch(actions.set_forgot_sent(true));
      return Promise.resolve();
    })
    .catch(translate_error)
    .then(
      make_log_error(
        private_actions.forgot_errors,
        { error: "System down, try again later" },
        dispatch,
      ),
    );
};
const reset_password = credentials => (dispatch, _getState) => {
  const post_obj = credentials;
  return dq_fetch("/api/v1/accounts/reset_password_confirm/", { post_obj })
    .then(() => {
      dispatch(private_actions.set_password_reset(true));
      return Promise.resolve();
    })
    .catch(translate_error)
    .then(make_log_error(private_actions.reset_errors, "", dispatch));
};
const add_email = email => (dispatch, getState) => {
  const user_id = selectors.user_id(getState());
  dispatch(private_actions.change_errors({}));
  return dq_fetch(`/api/v1/accounts/users/${user_id}/`, {
    post_obj: { email },
    type: "PATCH",
  })
    .then(() => refresh_user()(dispatch, getState))
    .catch(translate_error)
    .then(
      make_log_error(
        private_actions.change_errors,
        {
          email: [
            "This email address has already been used.  Please try another one.",
          ],
        },
        dispatch,
      ),
    );
};

const delete_account = () => (dispatch, getState) =>
  dq_fetch("/api/v1/accounts/delete_account/", { type: "POST" })
    .then(() => {
      logout()(dispatch, getState);
    })
    .catch(err => {
      if (err.response.status === 400) {
        return err.response.json().then(message => {
          throw new Error(message);
        });
      }
      throw err;
    });

const get_social_token = (if_error_action, new_user) => (
  dispatch,
  getState,
) => {
  const url = "/api/v1/accounts/get_social_token/";
  const marketing_info = get_marketing_info_from_cookies();
  return dq_fetch(url, { get_params: marketing_info })
    .then(new_token(dispatch, getState, new_user))
    .catch(() => dispatch(if_error_action));
};
const get_social_token_signup = () =>
  get_social_token(
    private_actions.signup_errors([{ general: "Authorization Failed" }]),
    true,
  );
const get_social_token_login = () =>
  get_social_token(
    private_actions.login_errors({ error: ["Authorization Failed"] }),
    false,
  );

const removeCookie = () => {
  Cookies.remove("token");
  Cookies.remove("dq-impersonate-token");
  if (__PROD__) Cookies.remove("dq-shared-auth", { domain: ".dataquest.io" });
  window?.localStorage?.removeItem("dq-appstream-notification");
};

const logout = () => () => {
  removeCookie();
  localStorage.removeItem("welcomeDialogDisplayed");
  if (!__DEV__) {
    Sentry.setUser(null);
  }
  window_location.redirect_after_logout();
};

const parseFeatureStatuses = data => {
  let cleanedData = data;
  const features = Object.keys(data);
  if (features.length && cleanedData[features[0]].status !== undefined) {
    cleanedData = {
      ffNotes: {},
    };
    features.forEach(feature => {
      cleanedData[feature] = data[feature].status;

      let featureNotes;
      try {
        featureNotes = JSON.parse(data[feature].note);
      } catch (SyntaxError) {
        featureNotes = null;
      }
      cleanedData.ffNotes[feature] = featureNotes;

      if (data[feature].multi_variant)
        cleanedData[`${feature}Alt`] = data[feature].status === false;
    });
  }
  return cleanedData;
};

const fetchFeatureStatus = () => (dispatch, _getState) =>
  dq_fetch(`/api/v1/accounts/get_feature_flag_status/`)
    .then(data => {
      const featureStatuses = parseFeatureStatuses(data);
      if (featureStatuses.injectLesson) {
        dispatch(fetchAbLessonData(featureStatuses.ffNotes.injectLesson));
      }
      dispatch(private_actions.setFeatureFlags(featureStatuses));
      dispatch(private_actions.setFeatureFlagsRetrieved(true));
    })
    .catch(_err => dispatch(private_actions.setFeatureFlagsRetrieved(true)));

const setFeatureStatus = featureName => (dispatch, _getState) => {
  dispatch(private_actions.setFeatureFlagsUpdated(true));
  return dq_fetch(`/api/v1/accounts/set_feature_flag_status/`, {
    post_obj: { feature_name: featureName },
  })
    .then(data => {
      dispatch(private_actions.setFeatureFlags(parseFeatureStatuses(data)));
      dispatch(private_actions.setFeatureFlagsRetrieved(true));
    })
    .catch(_err => dispatch(private_actions.setFeatureFlagsRetrieved(true)));
};

const fetchSubscribeCTASettings = () => (dispatch, _getState) => {
  const query = "{subscribe_cta {button_text, button_color}}";
  dq_graphql(query)
    .then(data => {
      dispatch(private_actions.setSubscribeCTASettings(data));
      dispatch(private_actions.setSubscribeCTASettingsRetrieved());
    })
    .catch(_err =>
      dispatch(private_actions.setSubscribeCTASettingsRetrieved()),
    );
};

/**
 * Retrieve and validate token
 * @returns {Promise}
 */
const try_login_via_cookie = () => (dispatch, getState) => {
  const token = get_token();
  const impersonateToken = getImpersonateToken();

  const uid = window_location.get_query_variable("dq_uid");
  if (uid) Cookies.set("dq-impersonate-token", uid);
  let authToken =
    uid || impersonateToken ? `?uuid=${uid || impersonateToken}` : "";

  // eslint-disable-next-line no-underscore-dangle
  if (isEmpty(token) && !authToken) {
    dispatch(private_actions.setAskingForUser(false));
    return Promise.resolve();
  }
  if (Cookies.get("forcedAuthSubscriptionStatus")) {
    authToken = `?force_auth=${Cookies.get("forcedAuthSubscriptionStatus")}`;
  }
  if (authToken) dispatch(private_actions.setImpersonationMode());
  dispatch(private_actions.setAskingForUser(true));

  const is_new_user = window.location.hash === "#is_new";

  const onboardingData = getSkipOnboardingData();
  const postData = onboardingData
    ? {
        post_obj: { skip_onboarding: onboardingData },
        csrf: true,
      }
    : undefined;
  return dq_fetch(`/api/v1/accounts/get_auth_token_data/${authToken}`, postData)
    .then(new_token(dispatch, getState, is_new_user))
    .catch(e => {
      removeCookie();
      window.location.reload();
    });
};

const set_analytics_info = user_id => {
  if (__DEV__) {
    console.log(
      `in DEV /api/v1/accounts/users/${user_id}/analytics_info/ is not called`,
    );
    return;
  }
  const gaTimeout = 1000;

  const post_id = post_obj => {
    dq_fetch(`/api/v1/accounts/users/${user_id}/analytics_info/`, {
      post_obj,
    }).catch(); // normal to see 405 issues
  };

  if (__TEST__) {
    return; // the following call is hard to mock and not tested anyways
  }

  setTimeout(() => {
    post_id({});
    EventsTracker.ready(() => {
      const clientID = getGAClientID();
      if (!clientID) return;
      post_id({ ga_client_id: clientID });
    });
  }, gaTimeout);
};

const setup_analytics = (dispatch, getState) => {
  if (window.analytics == null) {
    console.warn("analytics is missing");
    return;
  }

  do_identify(getState);
  const user_id = selectors.user_id(getState());
  set_analytics_info(user_id);
};

const do_identify = (getState, extra_traits = {}) => {
  if (selectors.team_id(getState())) return;

  const firstName = selectors.first_name(getState());
  const lastName = selectors.last_name(getState());
  const fullName = `${firstName} ${lastName}`;

  const traits = {
    name: fullName,
    email: selectors.user_email(getState()),
    userJoinedAt: selectors.date_joined(getState()),
    plan: selectors.plan_name(getState()),
    ...utm_selectors.codes(getState()),
    ...extra_traits,
  };

  // NOTE: should we change this to be the user_id instead?
  // Would this impact reporting in any way?
  const id = selectors.intercom_id(getState());

  EventsTracker.identify(id, traits);
};

const send_sso_request = query_param => _dispatch => {
  const url = `/api/v1/accounts/sso/${query_param}`;
  return dq_fetch(url).then(data => data);
};

const sendIdentityTreats = newTreats => (dispatch, getState) => {
  do_identify(getState, newTreats);
};

const enableMFA = authCode => (dispatch, _getState) => {
  const query = `mutation {edit_mfa(mfa_token: "${authCode}"){success}}`;
  return dq_graphql(query)
    .then(response => {
      const success =
        response.data && response.data.edit_mfa
          ? response.data.edit_mfa.success
          : false;
      if (success) dispatch(private_actions.setMFAEnabled(true));
      else
        dispatch(
          private_actions.setMFAErrors("The verification code is incorrect"),
        );
    })
    .catch(e => {
      console.warn(e);
    });
};

const disableMFA = password => (dispatch, _getState) => {
  const query = `mutation {delete_mfa(password: "${password}"){success}}`;
  return dq_graphql(query)
    .then(response => {
      const success =
        response.data && response.data.delete_mfa
          ? response.data.delete_mfa.success
          : false;
      if (success) dispatch(private_actions.setMFAEnabled(false));
      else
        dispatch(
          private_actions.setMFAErrors("Provided password is incorrect"),
        );
    })
    .catch(e => {
      console.warn(e);
    });
};

const getMFAUri = () => (dispatch, _getState) => {
  const query = "{mfa_provisioning_uri}";

  return dq_graphql(query)
    .then(response => {
      if (response.data && response.data.mfa_provisioning_uri)
        dispatch(private_actions.setMFAUri(response.data.mfa_provisioning_uri));
    })
    .catch(e => {
      console.warn(e);
    });
};

const resendVerificationEmail = () => (dispatch, _getState) => {
  const resendVerificationEmailQ =
    "mutation {resend_verification_email{success}}";

  return dq_graphql(resendVerificationEmailQ)
    .then(() => {
      setTimeout(
        () => dispatch(private_actions.setVerificationEmailSent(true)),
        500,
      );
    })
    .catch(e => {
      console.warn(e);
      setTimeout(
        () => dispatch(private_actions.setVerificationEmailSent(true)),
        500,
      );
    });
};

/**
 * Get user welcome kit steps
 */
export const getWelcomeKitSteps = () => (dispatch, _getState) => {
  dispatch(private_actions.setLoadingWelcomeKit(true));
  const getWelcomeKitStepsQ = `{welcome_kit_progress {completed, welcome_kit_steps{step_name, step_name_id, step_reward, completed}}}`;

  return dq_graphql(getWelcomeKitStepsQ)
    .then(data => {
      dispatch(
        private_actions.setWelcomeKitSteps(
          data.data.welcome_kit_progress.welcome_kit_steps,
        ),
      );
      dispatch(private_actions.setLoadingWelcomeKit(false));
    })
    .catch(() => {
      dispatch(private_actions.setLoadingWelcomeKit(false));
    });
};

/**
 * Mark welcome kit step as done
 */
export const setWelcomeKitStepDone = step => (dispatch, getState) => {
  const setWelcomeKitStepDoneQ = `mutation{complete_welcome_kit_step(step_name_id: "${step.step_name_id}"){success}}`;
  // Mark welcome kit step as completed, and refresh user if rewards flag
  // is enabled as user might have gained Premium status
  return dq_graphql(setWelcomeKitStepDoneQ).then(data => {
    const featureFlags = selectors.featureFlags(getState());
    if (featureFlags.welcome_kit_incentivesAlt) return dispatch(refresh_user());

    return data;
  });
};

export default {
  refresh_user,
  login,
  signup,
  forgot_password,
  reset_password,
  add_email,
  delete_account,
  get_social_token_signup,
  get_social_token_login,
  logout,
  try_login_via_cookie,
  do_identify,
  send_sso_request,
  getMFAUri,
  enableMFA,
  disableMFA,
  fetchFeatureStatus,
  setUniqueSessionId,
  setSkipOnboarding,
  setReferral,
  setFeatureStatus,
  sendIdentityTreats,
  fetchSubscribeCTASettings,
  resendVerificationEmail,
  getWelcomeKitSteps,
  setWelcomeKitStepDone,
};
